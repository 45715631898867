// Visualizer Style fixes
/*
.ReactModal__Body--open {
	.modal {
		display: block;
		overflow: visible;
		height: auto;
		position: relative !important;
	    @media( min-width: 400px ){
	        padding: 2em 1em;
	    }
	    @media( min-width: 500px ){
	        padding: $pad-y*2 $pad-x*3;
	    }
	    @media( min-width: 800px ){
	        width: 80%;
	        padding: $pad-y*3 $pad-x*3;
	    }
	    @media( min-width: 1000px ){
	        width: 80%;
	    }
	}
}
*/
.vehicle-color-options-button .label {
	font-size: inherit;
}

.velox-visualizer-sidebar.is-mobile .vehicle-color-options-button.btn img.icon-color-palette {
	margin-right: 0.5em;
}

.velox-vehicle-visualizer {
	width: 100%;
	.vxviz-sidebar-label {
		position: relative;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
		}
		.accordion-item-value {
			margin-left: 1.3rem;
		}
	}
}

.no-flexbox {
	.velox-vehicle-visualizer {
		.toolbar {
			display: block;
			width: 100%;
			&>li.sort-options {
				display: block;
				position: relative;
				padding-left: 2em;
				.icon-sort {
					width: 2em;
					height: 2em;
					position: absolute;
					left: 0;
					top: 0;
					padding: 0.8em 0.3em 0.3em;
				}
				.dropdown {
					display: inline-block;
					width: 50%;
					vertical-align: center;
				}
			}
		}
		.wheel-results .toolbar .sort-options {
			display: block;
			.icon-sort, .dropdown {
				display: inline-block;
			}
			.icon-sort {
				float: left;
			}
		}
		.select-quantity-wrapper {
			display: block;
			.select-qty,
			.checkout-button {
				display: inline-block;
			}
		}
		.product-details-header {
			.close-details {
				display: block;
				.icon-cancel,
				.vxviz-label {
					display: inline-block;
				}
			}
		}
		.product-details-main-content-header {
			display: block;
			.left,
			.right {
				display: inline-block;
			}
		}
		.product-details-info {
			.wrapper {
				display: block;
				.product-details-info-description,
				.product-details-info-specs {
					display: inline-block;
					vertical-align: top;
					@media(max-width: 600px) {
						width: 100%;
						display: block;
					}
				}
			}
		}
	}
}
